import ContentLoader from 'react-content-loader'

export const DashboardMainHeaderTitleLoader = () => {
  return (
    <ContentLoader
      width={90}
      height={35}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      viewBox="0 0 90 35"
      data-cy="pq-loader__dashboard-title"
    >
      <rect x="0" y="5" rx="0" ry="0" width="90" height="25" />
    </ContentLoader>
  )
}
